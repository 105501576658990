#for-avatar{
    cursor: pointer;
    align-self: center;
    display: grid;
    grid-template-areas: "avatar more";
    grid-template-columns: 35px 35px;
    top: 0;
    right: 20px;
    z-index: 201;
    position: fixed;
    width: fit-content;
    white-space: nowrap;
    padding: 1px;
}
.avatar {
    display: grid;
    grid-area: avatar;
    font-weight: bold;
    color: white;
    background-color: #91A8B0;
    left: 0;
    margin: 0px 0px 2px 0px;
}
.more{
    display: grid;
    grid-area: more;
    color: rgb(255, 255, 255); 
    top: 8px;
    font-size: 2.0em;
    text-decoration-color: white;
    width: auto;
}

ul #configuracion{
    padding: 0%;
    width: 100%;
    background-color: aqua;
}

ul  .lista  {
    padding: 0%;
    width: 100%;
}

ul  .lista > li {
    align-content: center;
    padding-left: 30%;
    border-radius: 15px;
    width: auto;
    min-width: calc(100% - 30%);
    background-color: #214A5C;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 0;
    font-size: 0.8em;
}