.tablebonificaciones{
    margin-top: -50px;
}
.tablereportsbonif{
    margin: 0 auto;
    width: 100% !important;
    margin-top: -60px;
}
.tablereportsbonif .sticky-table-table{
    width: 100%;
}
.tablereportsbonif td:nth-child(1){
   min-width: 150px;
 }
.filter-7 p{
  height: 10px !important;
}
.tableaddsalesboni{
    margin-top: -50px;
}
.div2cM01{
    position: relative;
    z-index: 160;
}