@import './sass/variables';
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

.react-datepicker-wrapper {
  width: fit-content !important;
}

.react-datepicker-popper {
  z-index: 1000 !important;

  .react-datepicker__header {
    background-color: $neutralDark;
    
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month {

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: $primary;
    }

    .react-datepicker__day:hover, 
    .react-datepicker__month-text:hover, 
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      background-color: $primaryLighter;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: $primary;
    }
  }
}