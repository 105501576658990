.container-confirm{
    text-align: center;
    width: 100%;
}
.confirmar{
    font-size: 18px;
    color: black;
}
.confirmar span{
    font-weight: bold;
}
.input-confir{
    width: 80%;
    margin: 0 auto;
    font-size: 18px;
}
.input-confir input{
    width: 100%;
    font-size: 18px;
    padding: 10px;
}