.ContenerConponents {
  width: 95%;
  margin: 0 auto;
  margin-top: -70px;
}
.ctp-2{
  z-index: 170 !important;
}
.buscadorPersonalizado {
  width: 100%;
  margin-left: 23%;
  margin-top: 20.5px;
}

.buscadorPersonalizadoBUsnes {
  width: 103%;
  margin-top: 20.5px;
}

#fechaHora {
  width: 20%;
  margin-top: 10px;
  height: 39px;
  text-align: center;
  margin-right: 11px;
}

.buscadorNone {
  position: none !important;
}

.containerCartaportev4 {
  width: 90%;
  margin: 0 auto;
}

.ctp-1 {
  grid-area: cp1;
  padding-top: 2px;
}
.ctp-2 {
  grid-area: cp2;
  padding-left: 25px;
  padding-top: 16px;
}
.ctp-3 {
  grid-area: cp3;
}
.ctp-4 {
  grid-area: cp4;
}
.ctp-5 {
  grid-area: cp5;
}
.ctp-6 {
  grid-area: cp6;
}
.ctp-7 {
  grid-area: cp7;
}

.ctp-8 {
  grid-area: cp8;
  padding-left: 30px;
  margin-left:unset;
  margin-right: unset;
}
.ctp-9 {
  grid-area: cp9;
  padding-left: 12px;
  padding-top: 12px;
}
.ctp-10 {
  grid-area: cp10;
}
.ctp-11 {
  grid-area: cp11;
}
.ctp-12 {
  grid-area: cp12;
}
.ctp-13 {
  grid-area: cp13;
}
.ctp-14 {
  grid-area: cp14;
}
.ctp-15 {
  grid-area: cp15;
}
.ctp-16 {
  grid-area: cp16;
}
.ctp-17 {
  grid-area: cp17;
}
.ctp-18 {
  grid-area: cp18;
}
.ctp-19 {
  grid-area: cp19;
}
.ctp-20 {
  grid-area: cp20;
}

.ctp-21 {
  grid-area: cp21;
}

.ctp-22 {
  grid-area: cp22;
}

.ctp-23 {
  grid-area: cp23;
}

.ctp-24 {
  grid-area: cp24;
}
.ctp-25 {
  grid-area: cp25;
}

.containerCartaportev4 {
  display: grid;
  grid-template-areas:
    "cp1 cp1 cp2 cp2 cp2 cp2 cp2 cp2 cp3 cp3"
    "cp4 cp4 cp5 cp5 cp6 cp6 cp7 cp7 cp8 cp8"
    "cp9 cp9 cp9 cp9 cp10 cp10 cp11 cp11 cp12 cp12"
    "cp13 cp13 cp13 cp13 cp14 cp14 cp15 cp15 cp16 cp16"
    "cp17 cp17 cp17 cp17 cp18 cp18 cp18 cp18 cp19 cp19"
    "cp17 cp17 cp17 cp17 cp18 cp18 cp18 cp18 cp19 cp19"
    "cp20 cp20 cp20 cp20 cp20 cp20 cp20 cp20 cp20 cp20";



  gap: 10px;
}

.contenedorConceptCartaPorte{
    width: 95%;
    margin:  0 auto;
}

.containerCartaporte{
  width: 60%;
  display: grid;
}

.containerCartaporte{
  display: grid;
  margin: 0 auto;
  grid-template-areas:
    "cp1 cp2";
  gap: 10px;
}

span.Cporte {
  font-size: 60px;
}