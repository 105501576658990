.notification-container {
  background-color: #FEEFB3;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 100%;
}

.notification-container-warning {
  background-color: #FEEFB3; /* Cambia el color de fondo para las notificaciones de advertencia */
  border-color: #ff9900; /* Cambia el color del borde para las notificaciones de advertencia */
  display: flex;

  align-items: flex-start !important;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;


}

.notification-container-error {
  background-color: #c04646; /* Cambia el color de fondo para las notificaciones de advertencia */
  display: flex;
  align-items: flex-start !important;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;


}


.notification-container-error .notification-content p,
.notification-container-error .notification-title span{
  color: white;
}
.notification-container-success .notification-content p,
.notification-container-success .notification-title span{
  color: #000000;
}




.notification-container-success {
  background-color: rgb(205 255 205); /* Cambia el color de fondo para las notificaciones de advertencia */
  border-color: #ff9900; /* Cambia el color del borde para las notificaciones de advertencia */
  display: flex;
  align-items: flex-start !important;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;


}


.icon-success,
.icon-warning {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.notification-title {
  font-weight: bold;
  font-size: 16px;
}

.notification-content {
  
  font-size: 14px;
  color: #333;
  white-space: normal !important;
  word-wrap: break-word; /* Permite que el texto se rompa en líneas cuando sea necesario */
  max-width: 100%;
  padding-left: 20px; 
  text-align: justify;
  font-family: 'Intruder Alert', sans-serif;
  padding-top: 5%;

}

/* Elimina el margen inferior en la última notificación */
.notification-container:last-child {
  margin-bottom: 0;
}

.notification{
  
}

.notifications-br{
  width: auto !important;
  max-width: 30% !important;
  min-width: 15% !important;
}

.notification-visible{
  box-shadow: rgb(0 0 0 / 90%) 0px 0px 0px !important;
  border-top: 0px solid rgb(94, 164, 0) !important;
  background-color: transparent !important;

}


.notification-dismiss{
  background-color:grey !important
}