
.TableMarcas .sticky-table-table{
    width: 100%;
  }

.subtablemodelo .sticky-table-table{
  width: 50%;
}

.TableMarcas{
  margin: 0 auto;
  width: 95% !important;
  margin-top: -50px;
}
.TableMarcas .titletable{
  margin-top: -30px;
  margin-bottom: -50px;
}


.TableMarcas td:nth-child(1),
.TableMarcas td:nth-child(2),
.TableMarcas td:nth-child(3),
.TableMarcas td:nth-child(4),
.TableMarcas td:nth-child(5){
  text-align: center;
}

.TableMarcas td:nth-child(2) {
  width: 120Px !important;
}

.subtablemodels .TableConsultarTodo2 td:nth-child(1) img{
  max-width: 30px;
  min-width: 30px;
}
.subtablemodels .TableConsultarTodo2 td:nth-child(1){
  width: 70px;
}

.gridmarca1, .gridmarca2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 95%;
    grid-template-areas:"a";
    grid-gap: 10px;
}
.marca1{grid-area: a;}
.marca2{grid-area: a;}

@media only screen and (max-width: 1080px) {
  .subtablemodels .TableConsultarTodo2 td:nth-child(1){
    max-width: 80px;
    min-width: 80px;
  }

}
 @media only screen and (max-width: 640px) {
  .subtablemodels .TableConsultarTodo2 td:nth-child(1){
    max-width: 100px;
    min-width: 100px;
 }
}
  