/* Estilo para el contenedor del input */
.file-input-container {
    position: relative;
    display: inline-block;
  }
  
  /* Estilo para el input de tipo file */
  .file-input {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
  }
  
  /* Estilo para el botón personalizado */
  .custom-file-input {
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Estilo para el rectángulo rojo */
  .custom-file-input:before {
    content: 'Selecciona archivo';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e74c3c;
    color: #fff;
    border-radius: 5px;
    pointer-events: none;
  }
  
  /* Estilo para resaltar el botón cuando se pasa el cursor por encima */
  .custom-file-input:hover {
    background-color: #45aaf2;
  }
  
  /* Estilo para resaltar el botón cuando está activo */
  .custom-file-input:active {
    background-color: #2980b9;
  }
  

  .container-file{
    margin: 0 auto;
    width: 55%;
  }


  .containerPricelist {
    display: grid;
    margin: 0 auto;
    grid-template-areas: "expen1 expen2 ";
    gap: 10px;
    position: absolute;
    width: 50%;
    z-index: 1;
    padding-left: 3%;
  }


.TablePriceListDetail thead tr th:nth-child(1),
.TablePriceListDetail td:nth-child(1){
    text-align: center !important;
    width: 100px !important;
}