.TableEditSales{
  margin: 0 auto;
  width: 95% !important;

}

.TableEditSales tr td{
  white-space: normal !important;

}

.buttonbackedit{
  height: 70px;
}
#buttonbackedit {
  font-size: 13px;
  width: 100px;
  float: left;
  font-weight: bold;
}

.package_data{
  float: right;
  font-size: 15px;
}
.package_data p{  
  float: left;
  padding-top: 10px;
}
.package_data p #getitem{
  font-weight: bold;
}


/* CUERPO TABLA */

.TableEditSales td:nth-child(1){
  text-align: center;
}

.TableEditSales td:nth-child(2),
.TableEditSales td:nth-child(3),
.TableEditSales td:nth-child(4){
  text-align: left;
}
.TableEditSales td:nth-child(5),
.TableEditSales td:nth-child(6),
.TableEditSales td:nth-child(7),
.TableEditSales td:nth-child(8){
  text-align: center;
}
.TableEditSales td:nth-child(9){
  text-align: right;
}

.contentbotons button{
  margin-left: 50px;
}

#countsaledit{
  float: right;
  width: 20%;
  font-size: 18px;
  padding-top: 20px;
}
.contenidoedit{
  width: 80%;
}
/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableEdiSales  {
      font-size: 13px;
      width: 97%;
  }
  

}

@media only screen and (max-width: 1320px) {
  .TableEditSales  {
      font-size: 12px;
      width: 97%;
  }

}

@media only screen and (max-width: 1059px) {
    .TableEditSales  {
        font-size: 9.5px;
        width: 98%;
    }
}

@media only screen and (max-width: 890px) {
    .TableEditSales  {
        font-size: 8.5px;
    }
    .TableEditSales{
        width: 100%;
    }
}

/*estilos del input paper*/
.paper{
  width: 90px;
}
.NoInput{
  width: 13px!important;
} 


.InputVacio{
  background-color: red;
  color: white;
  font-size: 15px;
  margin-bottom: 3px;
}

.listBreadCrum{
  color: #4183c4 !important;
}
