.TableTaxes {
  margin: 0 auto;
  width: 95%;
}

/* CUERPO TABLA */


.TableTaxes td:nth-child(1),
.TableTaxes td:nth-child(2),
.TableTaxes td:nth-child(3),
.TableTaxes td:nth-child(4),
.TableTaxes td:nth-child(5),
.TableTaxes td:nth-child(7){
  text-align: center;
}

/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableTaxes  {
      font-size: 13px;
      width: 97%;

  }
}

@media only screen and (max-width: 1320px) {
  .TableTaxes  {
      font-size: 12px;
      width: 97%;

  }
}

@media only screen and (max-width: 1059px) {
    .TableTaxes  {
        font-size: 9.5px;
        width: 98%;

    }
}

@media only screen and (max-width: 890px) {
    .TableTaxes  {
        font-size: 8.5px;
    }
    .TableTaxes{
        width: 100%;
    }
}


.buttoncancel{
  background-color: red !important;
}


.taxes{
  cursor: pointer;
  margin-left: 1px;
  margin-right: 1px;
}

.ButtonModal{
  background-color: green !important;
}

.ButtonModal2{
  background-color: red !important;
}

.divEncabezado{
  position: absolute;
  padding-left: 40%;
}