.modal-create-case {
  width: 90%;
  max-width: 1000px;
  position: fixed !important;  
  font-size: 1.3em;
  text-align: center;
}

.modal-content {
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #fff;
  width: auto;
  max-width: 100%;
  padding: 0px;
  border-radius: 4px;
  outline: none;
  text-align: inherit;
}

.modal-header {
  display: flex;
  flex-direction: row;
  flex: 100%;
  justify-content: space-between;
}

.p1 {
  grid-area: p1;
  background-color: rgb(228, 227, 227);
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin: 0% 5px 0% 20%;
  text-align: center;
  border: 1px solid black;
}

.p2 {
  grid-area: p2;
  background-color: rgb(228, 227, 227);
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin: 0% 20% 0% 5px;
  text-align: center;
  border: 1px solid black;
}

.modal-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkbox-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.checkbox-item {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  width: 50%;
  padding-inline: 10px;
  margin: 5px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.helper-check{
  color: red;
  font-style: italic;
  font-weight: lighter;
}

.backdrop{
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  width:100%;   
  background-color: white;
  z-index: 10020;
}

.select-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;  
  text-align: start;
}
 
.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}

.breadcrumb {
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
}

.form-item {
  width: 100%;
  margin-inline: 0;
  margin-bottom: 15px;  
}

.label-select {
  background-color: #fff;
  font-weight: bold;
  font-size: large;
}

.step-content{
  padding: 0px 5%;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.checkbox-title {
  width: 100%;
  justify-self: center;
  align-content: center;
}

.carrusel-container {
  width: 100%;
  overflow-x: auto;
}

.carrusel-img {
  display: flex; 
  overflow-x: auto;
}

.img-support {
  margin-right: 10px; 
  flex-shrink: 0; 
  position: relative;
}
.img-support img{
  width: 200px !important;
}
.img-support .img-delet{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  height: 30px !important;
  width: 30px !important;
}
.img-support .img-delet:hover{
  cursor: pointer;
}
.input-img-file{
  width: 130px !important;
  margin-right: 10px;
}
.form-item-img{
  text-align: left;
}