.container-map-relation{
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.container-map{
  width: 95%;
  margin: 0 auto;
  height: 80vh;
}
.fondo-map-modal{
  /* background-color: rgb(11, 29, 85); */
}


.custom-node {
  width: 350px !important;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 
  "a"
  "b";
}
.custom-title{
  grid-area: a;
  width: 100%;
  height: 40px;
  padding: 5px;
}
.custom-details{
  grid-area: b;
  padding: 5px;
  background-color: white;
}
.custom-title h2{
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: black;
}

.custom-node p {
  margin: 0;
  padding: 5px 0;
  font-weight: bold;
}
.custom-details span{
  font-weight: 100;
}
.descargar-pdf{
  text-align: right;
  margin-top: -50px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.descargar-pdf img{
  width: 30px;
}


.ppred{
  color: red;
}

.title-pago{
  background-color: rgb(71, 173, 246);
}
.title-nota{
  background-color: rgb(255, 255, 153); 
}
.title-factura{
  background-color: rgb(255, 101, 101);
}
.title-traslado{
  background-color: rgb(116, 226, 116);
}

.load_grap_map{
  width: 90%;
  text-align: center;
  margin: 0 auto;
  padding-top: 100px;
}
.load_grap_map img{
  width: 25%;
}

.load_grap_map p{
  font-size: 20px;
  color: rgb(8, 8, 91);
}