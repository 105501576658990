
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  
}

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.userName{
  float: right;
  margin-right: 10px;
  margin-top: -50px;
  font-size: 18px;
  color: white; 
}

.signout{
  float: right;
  margin-right: 10px;
}

.homeGrid{
  margin-top: 50px;
  display: grid;
  
  column-gap: 100px;
  
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  
  padding-top: 20px;  
}

