.welcome-container{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas:"a b";
    grid-gap: 20px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}
.title-dashboard{
    grid-area: a;
}
.button-tour{
    grid-area: b;
    text-align: right;
}
.button-tour button{
    padding: 15px;
    width: 70%;
    font-size: 25px;
    border-radius: 10px;
    background-color: rgb(240, 170, 40);
    color: black;
    font-weight: bold;
}
.button-tour button:hover{
    cursor: pointer;
}

.container-dashboard{
    width: 95%;
    margin: 0 auto;
}
.title-dashboard{
    font-size: 30px;
    text-align: left;
    width: 100%;
    margin: 0 auto;
    font-weight: bold;
}
.grid-dashboard{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 20% 20% 15% 20% 22%;
    grid-template-areas:
        "a a b b c"
        "d d d e c";
    grid-gap: 20px;
    width: 100%;
    position: relative;
    height: auto;
    margin-bottom: 20px;
}
.seccion1{
    grid-area: a;
    height: 380px;
    overflow: hidden;
}
.seccion2{
    grid-area: b;
    height: 380px;
    overflow: hidden;
}
.seccion3{
    grid-area: c;
}
.seccion4{
    grid-area: d;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 2px solid rgb(101, 101, 101);
}
.seccion5{
    grid-area: e;
    height: 300px;
    width: 100%;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 2px solid rgb(101, 101, 101);
}
.token-container{
    width: 100%;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 2px solid rgb(101, 101, 101);
    height: 200px;
    margin-bottom: 20px;
}
.token-container .token-title{
    height: 60px;
    background-color: rgb(239, 239, 239);
    font-size: 20px;
    padding-top: 15px;
    color: black;
    text-align: left;
    padding-left: 20px;
    font-weight: bold;
    background-color: rgb(35, 73, 103);
    color: white;
}
.token-time{
    padding-top: 20px;
    font-size: 25px;
}
.tiempo-restante{
    font-size: 40px;
    font-weight: bold;
}
.seccion-noticias{
    width: 100%;
    height: auto;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 2px solid rgb(101, 101, 101);
}
.title-noticias{
    height: 60px;
    background-color: rgb(239, 239, 239);
    font-size: 20px;
    padding-top: 15px;
    color: black;
    text-align: left;
    padding-left: 20px;
    font-weight: bold;
}
.title-moduls{
    height: 60px;
    background-color: rgb(239, 239, 239);
    font-size: 20px;
    padding-top: 15px;
    color: black;
    text-align: left;
    padding-left: 20px;
    font-weight: bold;
    background-color: rgb(35, 73, 103);
    color: white;
    
}
/* seccion hi */
.title-hi{
    height: 60px;
    background-color: rgb(239, 239, 239);
    font-size: 20px;
    padding-top: 15px;
    text-align: left;
    padding-left: 20px;
    font-weight: bold;
    color: black;
}
.publicidad-hi img{
    width: 100%;
}


.moduls-act{
    width: 100%;
    height: auto;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 2px solid rgb(101, 101, 101);
}
.cart-modulo{
    height: auto;
    max-height: 200px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas:"a b";
    grid-gap: 10px;
    width: 100%;
    position: relative;
}
.cart-modulo-icon{
    grid-area: a;
    padding-top: 10px;
}
.cart-modulo-icon img{
    width: 40px;
}
.cart-modulo-title{
    grid-area: b;
    text-align: left;
    width: 90%;
}
.cart-modulo-title .name-modu{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}
.cart-modulo-title .description-modu{
    font-size: 17px;
    text-align: justify;
 }

 .title-seccion4{
    height: 60px;
    font-size: 20px;
    padding-top: 15px;
    text-align: left;
    padding-left: 20px;
    font-weight: bold;
    background-color: rgb(35, 73, 103);
    color: white;
 }

.modulos-fav-star{
    height: auto;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-template-areas:
        "carta1 carta2 carta3 carta4"
        "carta5 carta6 carta7 carta8"
        "carta9 carta10 carta11 carta12"
        "carta13 carta14 carta15 carta16"
        "carta17 carta18 carta19 carta20"
        "carta21 carta22 carta23 carta24"
        "carta25 carta26 carta27 carta28"
        "carta29 carta30 carta31 carta32"
        "carta33 carta34 carta35 carta36";
    grid-gap: 10px;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.modulos-fav-star .cartas{
    padding: 10px;
    height: auto;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-areas:
        "a b";
    grid-gap: 10px;
    width: 100%;
    position: relative;
}
.cartas .iconofav{
    grid-area: a;
    height: 60px;
}
.cartas .iconofav{
    font-size: 30px;
}
.cartas .iconofav svg{
    width: 100%;
    font-size: 45px;
}
.cartas .desfav{
    grid-area: b;
    text-align: left;
    font-size: 18px;
}
.img-prox{
    padding-top: 10px;
}
.img-prox img{
    width: 50%;
}
.title-prox{
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: gray;
}