/* TABLE CONTAINER */

#crearsector:hover{
  cursor: pointer;
}

.package_data{
  float: right;
  font-size: 15px;
}
.package_data p{  
  float: left;
  padding-left: 20px;
  padding-top: 10px;
}
.package_data p #getitem{
  font-weight: bold;
}

/* Puntos de venta */
.pestañas {
  width: 100%;
}

.pestaña1 {
  padding-top: 5px;
  width: 50%;
  height: 30px;
}
.pestaña2 {
  padding-top: 5px;
  height: 30px;
  width: 50%;
}
.pestaña1, .pestaña2{
  display:inline-block
}
.pestaña1:hover{
  cursor:pointer;
}
.pestaña2:hover{
  cursor:pointer;
}

/* Detalles de un punto de venta */
.pestaña1d {
  padding-top: 5px;
  width: 33%;
  height: 30px;
}
.pestaña2d {
  padding-top: 5px;
  height: 30px;
  width: 33%;
}
.pestaña3d{
  padding-top: 5px;
  height: 30px;
  width: 33%;
} 
.pestaña1d, .pestaña2d, .pestaña3d{
  display:inline-block
}
.pestaña1d:hover{
  cursor:pointer;
}
.pestaña2d:hover{
  cursor:pointer;
}
.pestaña3d:hover{
  cursor:pointer;
}

.textareapuntosventa{
  padding: 10px;
  width: 90%;
  height: 200px;
  font-size: 19px;
  border-radius: 10px;
}

/* CUERPO TABLA */

.TablePointOfSales{
  margin: 0 auto;
  width: 98% !important;
}
.TablePointOfSales .sticky-table-table{
  width: 100%;
}
.TablePointOfSales td {
  white-space: normal !important;
}
.TablePointOfSales td:nth-child(1){
  min-width: 100px;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 20px;
  text-align: center;
}
.TablePointOfSales td:nth-child(1) img{
  width: 30px !important;
}
.TablePointOfSales td:nth-child(2){ 
  text-align: center;
}
.TablePointOfSales td:nth-child(3), 
.TablePointOfSales td:nth-child(4){ 
    text-align: left;
}
.TablePointOfSales td:nth-child(5){ 
  text-align: center;
}
.TablePointOfSales td:nth-child(6),
.TablePointOfSales td:nth-child(7){ 
  text-align: left;
}
.TablePointOfSales td:nth-child(8),
.TablePointOfSales td:nth-child(9),
.TablePointOfSales td:nth-child(10),
.TablePointOfSales td:nth-child(11){ 
  text-align: center;
}
.TablePointOfSales td:nth-child(12){
  text-align: left;
}
.TablePointOfSales th:nth-child(8) #custom-select{
  width: 100% !important;
  height: 32px;
  font-size: 12px !important;
  text-align: left;
}

.TablePointOfSales th:nth-child(11) #custom-select{
  width: 100% !important;
  height: 32px;
  font-size: 12px !important;
  text-align: left;
}

.TablePointOfSales .titletable{
  margin-top: -28px;
  margin-bottom: -50px;
}
.TablePointOfSales td:nth-child(37),
.TablePointOfSales thead th:nth-child(37),
.TablePointOfSales td:nth-child(34),
.TablePointOfSales thead th:nth-child(34)
{
  text-align: justify !important;
  max-width: 110px !important;
  white-space: normal;
}

/*Tabla de puntos de venta*/

/* crear un punto de venta */
/* codigo, nombre y codigo unilever */
.p_alert{
  font-size: 13px;
  color: red;
  font-weight: bold;
  margin-top: -10px;
}
.p_alerta{
  font-size: 13px;
  padding-top: 15px;
  color: red;
  font-weight: bold;
}

.grid01{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 17% 17% 20%;
  grid-template-areas:
      "a b c c c d";
  grid-gap: 10px;
}
.grid01edit{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 17% 17% 20%;
  grid-template-areas:
      "a c c c c d";
  grid-gap: 10px;
}

.item1{grid-area: a;}
.itemch{grid-area: b;}
.itemch label{
  transform: scale(1.5);
}
.item2{grid-area:c;}
.item3{grid-area: d;}

.grid02{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 17% 17% 20%;
  grid-template-areas:
   "d d d d e e";
  grid-gap: 10px;
}
.item4{grid-area: d;}
.item5{grid-area: e}
.item6{grid-area: d;}
.item7{grid-area: e;}

.grid03{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 17% 17% 20%;
  grid-template-areas:
   "a a b c d d";
  grid-gap:10px;
}
.grid03edit{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 17% 17% 20%;
  grid-template-areas:
   "e e e e f g";
  grid-gap:10px;
}

.grid03pos{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 17% 17% 20%;
  grid-template-areas:
   "e e e e f g";
  grid-gap:10px;
}


.item8{grid-area: a;}
.item9{grid-area: b;}
.item10{grid-area: c;}
.item11{grid-area: d;}
.item12{
  grid-area:e;
  padding-top: 10px;
  margin-left: 10px;
}
.item12z{
  grid-area: e;
}
.item12a{
  grid-area: e;
}
.item13{grid-area: f;}
.item14{grid-area: g;}
.grid04{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 22% 15% 17%;
  grid-template-areas:
   "a a b c d d";
  grid-gap: 10px;
}
.grid04b{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 22% 15% 17%;
  grid-template-areas:
   "a a b c d d";
  grid-gap: 10px;  
}
.item15{grid-area: a;}
.item16{grid-area: b;}
.item17{grid-area: c;}
.item18{grid-area: d;}

.grid05{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 6% 17% 17% 17% 20%;
  grid-template-areas:
   "a a b c d d";
   grid-gap: 10px;
}
.grid05b{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 6% 17% 17% 17% 20%;
    grid-template-areas:
     "a a a d d d";
    grid-gap: 10px;
  }
  
.item19{grid-area: a;}
.item20{grid-area: b;}
.item21{grid-area: c;}
.item21b{grid-area: d;}


/* Seccion de direcciones */
.gridd01{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 12% 12% 12% 12% 12%;
    grid-template-areas:
     "a b c d d d d";
    grid-gap: 10px;
}
.gridd02{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 15% 12% 12% 12% 12% 12%;
  grid-template-areas:
   "a b c c c d d";
  grid-gap: 10px;
}

.gridd01vista{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 15% 12% 12% 12% 12% 12%;
  grid-template-areas:
   "a b d d d d d";
  grid-gap: 10px;
}
.gridd03{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 15% 12% 12% 12% 12% 12%;
  grid-template-areas:
   "a a b b c c d";
  grid-gap: 10px;
}

.gridd04{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 15% 15% 12% 12% 12% 12% 12%;
  grid-template-areas:
   "a a a a b b b";
  grid-gap: 10px;
}

.item22{grid-area: a;}
.item23{grid-area: b;}
.item23bb{grid-area: c;}
.item23bb img{
  width: 50px;
  height: 60px;
  padding-top: 10px;
}
.item24{grid-area: d;}

.item25{grid-area: a;}
.item26{grid-area: b;}
.item27{grid-area: c;}
.item28{grid-area: d;}


.item29{grid-area: a;}
.item30{grid-area: b;}
.item31{grid-area: c;}
.item32{grid-area: d;}


.item33{grid-area: a;}
.item34{grid-area: b;}
.item35{grid-area: c;}
.item36{grid-area: d;}


@media only screen and (max-width: 720px) {
  .grid01{
    grid-template-areas: 
    "a a a a a b"
    "c c c c c c"
    "d d d d d d"
  }
  .grid01edit{
    grid-template-areas:
      "a a a d d d"
      "c c c c c c" 
    ;
   }
.grid02{
  grid-template-areas:
    "d d d d d d"
    "e e e e e e"
  }
 .grid03{
  grid-template-areas:
   "a a a a a a"
   "b b b b b b"
   "c c c c c c"
   "d d d d d d"
 }
 .grid03pos{
  grid-template-areas: 
  "e e e e e e"
  "f f f f f f"
  "g g g g g g"
  ;
 }
 .item12{
  padding-bottom: 10px;
 }
 .grid04{
   grid-template-areas:
   "a a a a a a"
   "b b b b b b"
   "c c c c c c"
   "d d d d d d";
}
.grid05{
  grid-template-areas:
   "a a a a a a"
   "b b b b b b"
   "c c c c c c"
   "d d d d d d";
}
.gridd01{
  grid-template-areas:
   "a a a a a a a"
   "b b b b b b b"
   "c d d d d d d";
 }
 
.gridd02{
  grid-template-areas:
   "a a a a a a a"
   "b b b b b b b"
   "c c c c c c c"
   "d d d d d d d";
}
.gridd03{
  grid-template-areas:
   "a a a a a a a"
   "b b b b b b b"
   "c c c c c c c"
   "d d d d d d d"
}
.gridd04{
   grid-template-areas:
   "a a a a a a a"
   "b b b b b b b"
}

/*Editar punto de venta*/
.grid01edit{
  grid-template-areas:
  "a a a a a a"
  "c c c c c c"
  "d d d d d d";
}
.grid03edit{
  grid-template-areas:
   "e e e e e e"
   "f f f f f f"
   "g g g g g g";
}
.grid04b{
  grid-template-areas:
   "a a a a a a"
   "b b b b b b"
   "c c c c c c"
   "d d d d d d";
}
.grid05b{
  grid-template-areas:
   "a a a a a a"
   "d d d d d d"
  }
.gridd01vista{
  grid-template-areas:
   "a a a a a a a"
   "b b b b b b b"
   "d d d d d d d";
}
}

.modalpuntosventa{
  /* width: 90%; */
  max-width: 70% !important;
  text-align: center;
  padding: 20px;
}
.formulariopointofsale label{
  color: black;
  font-size: 17px; 
}
.formulariopointofsale div input{
  height: 52px;
  /* border: 1px solid #76b5c5; */
  font-size: 15px;
  color: black;
}

.formulariopointofsale div input{
  border: 1px solid #A5A5A5;
}

.formulariopointofsale div label {
  font-size: 19px;
  color: black;
  background-color: white;
}
.formulariopointofsale div select {
  border: 1px solid #A5A5A5;
  color: black;
}


/* modal de puntos de venta por sector */
.modalpof{
  margin: 0 auto;
  padding: 10px;
  max-width: 55% !important;
  text-align: center;
}
.contentposs{
  width: 100%;
}
.ordensec{
 margin-top: 10px;
  width: 25%;
  padding-right: 30px; 
}

.searchautop{
  width: 60%;
}
.ordensec,.searchautop{
  display: inline-block
}


/* Tabla de detalles de un punto de venta seccion de sectores */
.Tablesectorespos{
  width: 90%;
  margin-left: 5%;
}

.Tablesectorespos {
  border: 2px solid #d3d5d5;
}
.Tablesectorespos th {
  border: 2px solid #d3d5d5;
}
.Tablesectorespos td {
  border: 2px solid #d3d5d5;
}
.Tablesectorespos td:nth-child(1){
  text-align: center;
  max-width: 150px;
  min-width: 100px;
} 

.Tablesectorespos thead{
  font-size: 17px;
  font-weight: bold;
  height: 40px;
}
.Tablesectorespos thead tr th {
  background-color:#3f87a6;
  color: white;
}

.Tablesectorespos tbody{
  font-size: 17px;
  height: 40px;
  font-size: 17px;
  color: black;
  text-align: center;
}
.Tablesectorespos tbody tr td {
  background-color: white;
  height: 30px;
 }

/* Tabla de detalles de un punto de venta */
.h1conge{
 font-size: 20px;
 width: 100%;
 color: black;
 text-align: left;
 padding-top: 20px;
 padding-left: 5%; 
}



.Tablecongelador{
  width: 90%;
  margin-left: 5%;
}

.Tablecongelador thead {
  font-size: 17px;
  font-weight: bold;
  height: 40px;
}
.Tablecongelador {
  border: 2px solid #d3d5d5;
}
.Tablecongelador th {
  border: 2px solid #d3d5d5;
}
.Tablecongelador td {
  border: 2px solid #d3d5d5;
}
.Tablecongelador thead tr th {
  background-color:#3f87a6;
  color: white;
}

.Tablecongelador tbody{
  font-size: 17px;
  height: 40px;
  font-size: 17px;
  color: black;
  text-align: center;
}
.Tablecongelador tbody tr td {
  background-color: white;
 }

/* Tabla de puntos de venta sin sector */
.TablePointOfSaleWS {
  width: 95%;
  margin: 0 auto;
}
.TablePointOfSaleWS .titletable{
  margin-top: -28px;
  margin-bottom: -50px;
}
.TablePointOfSaleWS .sticky-table-table{
  width: 100%;
}
.TablePointOfSaleWS td:nth-child(1){
  text-align: center;
}
.TablePointOfSaleWS td:nth-child(2){
  text-align: center;
}
.TablePointOfSaleWS td:nth-child(3){
  text-align: center;
}
.TablePointOfSaleWS td:nth-child(4){
  text-align: left;
}

.TablePointOfSaleWS td:nth-child(5),
.TablePointOfSaleWS td:nth-child(6),
.TablePointOfSaleWS td:nth-child(7),
.TablePointOfSaleWS td:nth-child(8),
.TablePointOfSaleWS td:nth-child(9)
{
  text-align: center;
}
.TablePointOfSaleWS th:nth-child(3) #custom-select,
.TablePointOfSaleWS th:nth-child(7) #custom-select,
.TablePointOfSaleWS th:nth-child(8) #custom-select{
  width: 100% !important;
  height: 32px;
  font-size: 12px !important;
  text-align: center;
}



/* tabla de sectores*/
.modalTItle label{
  font-size: 14px;
  background-color: white;
  color: black;
}
.modalTItle input{
  border: 1px solid #A5A5A5;
}
.modalTItle select{
  border: 1px solid #A5A5A5;
}
.TableSectors{
  margin: 0 auto;
  width: 95% !important;
} 
.Tablesect{
  margin-top: -60px;
}
.TableSectors .titletable{
  margin-top: -40px;
  margin-bottom: -30px;
}
.TableSectors .sticky-table-table{
  width: 100%;
}
.TableSectors td:nth-child(1){
  text-align: center;
  max-width: 70px;
  padding-right: 30px;
}
.TableSectors td:nth-child(2){
  text-align: center;
}
.TableSectors td:nth-child(3){
  text-align: left;
}
.TableSectors td:nth-child(4){
  text-align: center;
}
.TableSectors th:nth-child(4) #custom-select{
  width: 100% !important;
  height: 35px;
  font-size: 15px !important;
  text-align: center;
}

/* tabla de puntos de venta por sector */
.modalpof label{
  font-size: 20px;
  background-color: white;
  color: black;
}
.modalpof input{
  border: 1px solid #A5A5A5;
}
.modalpof select{
  border: 1px solid #A5A5A5;
}

.TablePointSaleSector .titletable{
  margin-top: -30px;
  margin-bottom:  -30px;
}
.TablePointSaleSector{
  margin: 0 auto;
  width: 98% !important;
}
.TablePointSaleSector tr td{
  white-space: normal !important;
}
.TablePointSaleSector .sticky-table-table{
  width: 100%;
}
.TablePointSaleSector td:nth-child(1){
  width: 100px;
  text-align: center;
}

.TablePointSaleSector th:nth-child(7)  #custom-select{
  width: 100% !important;
  height: 30px;
  font-size: 15px !important;
  text-align: center;
}
.TablePointSaleSector td:nth-child(2){
  text-align: center;
}
.TablePointSaleSector td:nth-child(3){
  text-align: center;
}
.TablePointSaleSector td:nth-child(4){
  text-align: left;
}
.TablePointSaleSector td:nth-child(5),
.TablePointSaleSector td:nth-child(6),  
.TablePointSaleSector td:nth-child(7){  
  text-align: center;
  width: 10%;
}


/* campo de punto de venta */
.griddis1{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 50% 50%;
  grid-template-areas:"a a"
}
.inputposdis {
  width: 100%;
  height: 70px;
  padding-top: 10px;
}
.itemdis1{grid-area: a; padding-left: 10px;}
.itemdis1e{grid-area: a;}

.itemdis00{grid-area: a;}
.itemdis01{grid-area: b;}

.itemdisfi{grid-area: a;}
.itemdisff{grid-area: b;}

.itemdisfi label, .itemdisff label{
  font-size: 18px;
}
.itemdis2{grid-area: a;}

.griddis2{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 33% 34% 33%;
  grid-template-areas:"a b c";
}

.griddis4{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 33% 32% 33%;
  grid-template-areas: "a a a";
}


.griddis5{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 33% 32% 33%;
  grid-template-areas: "a b c";
  grid-gap: 10px;
}

.griddis6{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 33% 32% 33%;
  grid-template-areas: "a a a";

}

.itemdis3{grid-area: a;}
.itemdis4{grid-area: b;}
.itemdis5{grid-area: c;}
.itemdis6{grid-area: a;}

@media only screen and (max-width: 480px) {
  .gridds0{
    margin-left: 10% !important;
    text-align: justify;
    grid-template-areas:
    "a a"
    "b b";    
  }
  .griddsfechas{
    margin-left: 10% !important;
    text-align: justify;
    grid-template-areas: 
    "a a"
    "b b";
  }
  .griddsfechas .fi, .griddsfechas .ff{
    width: 63% !important;
  }

  .griddis5{
    grid-template-areas: 
    "a a a"
    "b b c";
  }

}


.subtablepromotions td:nth-child(1){
  width: 10%;
}
.subtablepromotions td:nth-child(2){
  width: 40%;
  text-align: left;
}
.subtablepromotions td:nth-child(5){
  text-align: center;
}






/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableSectors  {
      font-size: 13px;
      width: 97%;
  }

}

@media only screen and (max-width: 1320px) {
  .TableSectors  {
      font-size: 12px;
      width: 97%;
  }
}

@media only screen and (max-width: 1059px) {
    .TableSectors  {
        font-size: 9.5px;
        width: 98%;
    }
}

@media only screen and (max-width: 890px) {
    .TableSectors  {
        font-size: 8.5px;
    }
    .TableSectors{
        width: 100%;
    }
    .TableSectors td:nth-child(1){
      width: 12%;
      text-align: center;
    }
}
@media only screen and (max-width: 640px) {
  .TableSectors  {
      font-size: 8.5px;
  }
  .TableSectors{
      width: 100%;
  }
  .TableSectors td:nth-child(1){
    width: 15%;
    text-align: center;
  }
}
.InputVacio{
  background-color: red;
  color: white;
  font-size: 15px;
  margin-bottom: 3px;
}

.alertNotificationP{
  color: white;
  padding-top: 0px;
  padding-bottom: 3px;
}




.promocampo6{
  position: relative;
}
.promocampo7 {
  position: relative;
}
.promocampo6 label{
  font-size: 15px !important;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 12px;
  margin-top: -12px;
}

.promocampo7 label{
  font-size: 15px !important;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 12px;
  margin-top: -12px;
}
