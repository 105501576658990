.confirmar-ticket-container {
    width: 500px;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: large;
}

.rate-container {
    flex: 100%;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    align-items: center;
    align-content: center;
    gap: 10px;
}

.opinion {
    width: max-content;
}