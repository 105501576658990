/*containerFactureResponse-1 fact-1... fact-5 */
.containerdailystaf {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.bp-1n {
  padding-top: 15px;
  padding-left: 10px;
  grid-area: cp1;
}
.bp-2 {
  grid-area: cp2;
}
.bp-3 {
  grid-area: cp3;
}
.bp-4 {
  grid-area: cp4;
}
.bp-5 {
  grid-area: cp5;
}
.bp-6 {
  grid-area: cp6;
}
.bp-7 {
  text-align: center;
  grid-area: cp7;
}

.bp-8 {
  grid-area: cp8;
}


.containerdailystaf {
  display: grid;
  grid-template-areas:
    "cp1 cp2 cp2 cp2"
    "cp3 cp3 cp4 cp4"
    "cp5 cp5 cp6 cp6"
    "cp7 cp7 cp8 cp8";
  
  gap: 10px;
}

p.validate_data {
  color: red !important;
  text-align: "justify";
  margin: 0 auto;
  width: 90%;
}

.containerProducts02 {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.containerProducts03 {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.containerProducts02 {
  display: grid;
  grid-template-areas:
    "cp1 cp2 cp2 cp2 cp9 cp3"
    "cp4 cp4 cp4 cp5 cp5 cp6"
    "cp7 cp7 cp7 cp8 cp8 cp8";
  gap: 10px;
}

.containerProducts03 {
  display: grid;
  grid-template-areas:
    "cp1 cp1 cp2 cp2 "
    "cp3 cp3 cp4 cp4"
    "cp7 cp7 cp8 cp8";
  gap: 10px;
}

.modalProduct{
  max-width: 80% !important;
}

.AddData{
  width: 150px;
  cursor: pointer;
}

.TableProduct thead th:nth-child(1){
  white-space: normal !important;
  width: 80 !important;
  max-width: 80 !important;
}
.TableProduct td th:nth-child(1){
  white-space: normal !important;
  width: 80 !important;
  max-width: 80 !important;
}

.AddDatapointer{
  cursor: pointer;
}


.selectProduct{
  width: 150px;
  position: absolute;
  margin-left: 2.8%;
  margin-top: 20px;
  font-size: 18px;

}
.selectProduct select{
  height: 40px;
}