.TableReport {
  margin: 0 auto !important;
  width: 95%;
}
.TableReport .sticky-table-table{
  width: 100%;
}

/* CUERPO TABLA */

.TableReport tr td{
  white-space: normal !important;
}

.TableReport td:nth-child(1),
.TableReport thead th:nth-child(1),
.TableReport td:nth-child(2),
.TableReport thead th:nth-child(2){
  text-align: center !important;
  max-width: 70px !important;
}

.TableReport thead th:nth-child(3),
.TableReport td:nth-child(3),
.TableReport thead th:nth-child(4),
.TableReport td:nth-child(4),
.TableReport thead th:nth-child(12),
.TableReport td:nth-child(12){
  text-align: center !important;
  max-width: 100px !important;
}

.TableReport thead th:nth-child(13),
.TableReport td:nth-child(13){
  text-align: center !important;
  max-width: 130px !important;
}

.TableReport thead th:nth-child(5),
.TableReport td:nth-child(5){
  text-align: center !important;
  max-width: 90px !important;
}


.TableReport td:nth-child(9),
.TableReport td:nth-child(10){
  text-align: center;
  height: auto;
}

.TableReport td:nth-child(6){
  text-align: left;
  text-overflow: ellipsis ;
  overflow: hidden;
}

.TableReport thead th:nth-child(7),
.TableReport td:nth-child(7){
  max-width: 100px!important;
}
.TableReport td:nth-child(7){
  text-align: right !important;
}
.TableReport td:nth-child(8),
.TableReport td:nth-child(10){
  text-align: center !important;
}


.TableReport thead th:nth-child(9),
.TableReport td:nth-child(9){
  max-width: 120px!important;
}
.TableReport thead th:nth-child(11),
.TableReport td:nth-child(11){
  max-width: 130px !important;
  text-align: center !important;
}


.TableReport td:nth-child(5){
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.TableReport td:nth-child(5),
.TableReport thead th:nth-child(5),
.TableReport td:nth-child(13),
.TableReport thead th:nth-child(13){
max-width: 130px;
}
.TableReport td:nth-child(14){
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.TableReport td:nth-child(3),
.TableReport td:nth-child(4),
.TableReport td:nth-child(5),
.TableReport td:nth-child(7),
.TableReport td:nth-child(8),
.TableReport td:nth-child(10),
.TableReport td:nth-child(11),
.TableReport td:nth-child(12),
.TableReport td:nth-child(13){
  text-align: justify;
}


/*CUERPO DE LA TABLA NO DEDUCIBLE*/

.ExpenseNoDeducible td:nth-child(2),
.ExpenseNoDeducible thead th:nth-child(2){
  text-align: center;
  width: 100px;
}
.ExpenseNoDeducible  td:nth-child(3),
.ExpenseNoDeducible  td:nth-child(4),
.ExpenseNoDeducible  td:nth-child(5),
.ExpenseNoDeducible  td:nth-child(10){
  text-align: center;
}
.ExpenseNoDeducible  td:nth-child(6){
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}
.ExpenseNoDeducible  td:nth-child(8){
  text-align: left;
}
.ExpenseNoDeducible  td:nth-child(7){
  text-align: right;
}

/*CUERPO DE LA TABLA POLIZAS*/

.PolicyTable  td:nth-child(3),
.PolicyTable  td:nth-child(5),
.PolicyTable  td:nth-child(7),
.PolicyTable  td:nth-child(8){
  text-align: center;
}

.PolicyTable  td:nth-child(6){
  text-align: left;
}


/*CUERPO DE LA TABLA GRUPO DE GASTOS*/


.GrupExpense  td:nth-child(5){
  text-align: center;
}

.GrupExpense thead th:nth-child(4),
.GrupExpense thead th:nth-child(3),
.GrupExpense thead th:nth-child(7),
.GrupExpense td:nth-child(7),
.GrupExpense td:nth-child(4),
.GrupExpense td:nth-child(3){

  max-width: 400px!important;
}

.GrupExpense thead th:nth-child(6),
.GrupExpense td:nth-child(6){
  max-width: 110px!important;
}
.GrupExpense td:nth-child(6){
  text-align: right !important;
}

.GrupExpense td:nth-child(7),
.GrupExpense td:nth-child(4){
  text-align: justify !important;
}
/*CUERPO DE LA TABLA DETALLES DEL GRUPO DE GASTOS*/
.GrupExpenseDetail  td:nth-child(7){
  text-align: justify !important;
}

.GrupExpenseDetail  td:nth-child(8){
  text-align: center;
}
.GrupExpenseDetail thead th:nth-child(6),
.GrupExpenseDetail td:nth-child(6),
.GrupExpenseDetail thead th:nth-child(3),
.GrupExpenseDetail td:nth-child(3){
  max-width: 100px!important;
}



.GrupExpenseDetail td:nth-child(6){
  text-align: right;
}

/*CUERPO DE LA TABLA DETALLES DE UNA POLIZA*/
.DetailPolicy td:nth-child(4),
.DetailPolicy td:nth-child(6),
.DetailPolicy td:nth-child(7),
.DetailPolicy td:nth-child(8){
  text-align: center;
}


.DetailPolicy td:nth-child(5){
  text-align: right;
}


/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableReport  {
      font-size: 13px;
      width: 97% !important;

  }

  .TableReport td:nth-child(6),
.TableReport thead th:nth-child(6),
.TableReport td:nth-child(11),
.TableReport thead th:nth-child(11),
.TableReport td:nth-child(8),
.TableReport thead th:nth-child(8),
.TableReport td:nth-child(14),
.TableReport thead th:nth-child(14){
max-width: 90px;
}

.ExpenseNoDeducible td:nth-child(6),
.ExpenseNoDeducible thead th:nth-child(6){
  max-width: 200px;

}
}

@media only screen and (max-width: 1320px) {
  .TableReport  {
      font-size: 13px;
      width: 97% !important;

  }
}

@media only screen and (max-width: 1059px) {
    .TableReport  {
        font-size: 12px;
        width: 98% !important;

    }
    .ExpenseNoDeducible td:nth-child(6),
.ExpenseNoDeducible thead th:nth-child(6){
  max-width: 90px;

}

}

@media only screen and (max-width: 890px) {
    .TableReport  {
        font-size: 12px;
    }
    .TableReport{
        width: 100% !important;
    }
}


.ExpenseCabeceraP td:nth-child(7){
text-align: center !important;
}


.buttoncancel{
  background-color: red !important;
}


.TableReport{
  cursor: pointer;
  margin-left: 1px;
  margin-right: 1px;
}

.TexAreaAutosize{
  width: 89%;
  margin: 0 auto;
  height: 89px !important;
}



/*containerFactureResponse-1 fact-1... fact-5 */
.containerExpense {
  width: 40%;
  margin: 0 auto;
  position: absolute;
  margin-left: 40px;
  padding-top: 20px;

}
.expen-1 {
  grid-area: expen1;
}
.expen-2 {
  grid-area: expen2;
}
.expen-3 {
  grid-area: expen3;
}
.expen-4 {
  grid-area: expen4;
}
.expen-5 {
  grid-area: expen5;
}
.expen-8 {
  grid-area: expen8;
}
.expen-7 {
  grid-area: expen7;
}

.color-5 p{
  font-size: 14px;
  font-weight: bold;
}




.containerExpense {
  display: grid;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen6";
  gap: 10px;
}

.containerExpenseReport{
  display: grid;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8 expen7";
  gap: 10px;
  position: absolute;
  width: 90%;
  padding-top: 20px;
  z-index: 3;
}
.containerExpenseReport2{
  display: grid;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8 expen7";
  gap: 10px;
  position: absolute;
  width: 90%;
  padding-top: 20px;
  z-index: 1;
  padding-left: 2.5%;
}



.fExpense{
  width: 100%;
}

.fExpense2{
  width: 100%;
  height: 35px !important;
}
/*generarExpense-1 expen-6... expen-7 */
.generarExpense {
  width: 89%;
  margin: 0 auto;
}
.expen-6 {
  grid-area: expen6;
  padding-top: 13px;
}
.expen-7 {
  grid-area: expen7;
}


.generarExpense {
  display: grid;
  grid-template-areas: "expen6 expen7" ;
  gap: 10px;
}


.ButtonModal{
  background-color: green !important;
}

.ButtonModal2{
  background-color: red !important;
}

.ButtonModalA{
  background-color: rgb(176, 206, 43) !important;
}
.ButtonModalG{
  background-color: rgb(2, 31, 192) !important;
}

.modalContainer .Modal-module_dialog__2UBIn {
  max-width: none !important;
  max-height: none !important;
  height: 100hv !important;
}


.info-msg,
.success-msg,
.warning-msg,
.error-msg {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
}
.info-msg {
  color: #059;
  background-color: #BEF;
}
.success-msg {
  color: #270;
  background-color: #DFF2BF;
}

.success-msg h2{
  color: #270;
  background-color: #DFF2BF;
}
.warning-msg {
  color: #9F6000;
  background-color: #FEEFB3;
}
.error-msg {
  color: #D8000C;
  background-color: #FFBABA;
}

.error-msg h2{
  color: #D8000C;
  background-color: #FFBABA;
}





.loader {
  font-size: 20px;
  margin: 45% auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  }
  @-webkit-keyframes load4 {
  0%,
  100% {
  box-shadow: 0em -3em 0em 0.2em #e40000, 2em -2em 0 0em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 -0.5em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 0em #e40000;
  }
  12.5% {
  box-shadow: 0em -3em 0em 0em #e40000, 2em -2em 0 0.2em #e40000, 3em 0em 0 0em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 -0.5em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  25% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 0em #e40000, 3em 0em 0 0.2em #e40000, 2em 2em 0 0em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 -0.5em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  37.5% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 0em #e40000, 2em 2em 0 0.2em #e40000, 0em 3em 0 0em #e40000, -2em 2em 0 -0.5em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  50% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 0em #e40000, 0em 3em 0 0.2em #e40000, -2em 2em 0 0em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  62.5% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 0em #e40000, -2em 2em 0 0.2em #e40000, -3em 0em 0 0em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  75% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 0em #e40000, -3em 0em 0 0.2em #e40000, -2em -2em 0 0em #e40000;
  }
  87.5% {
  box-shadow: 0em -3em 0em 0em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 0em #e40000, -3em 0em 0 0em #e40000, -2em -2em 0 0.2em #e40000;
  }
  }
  @keyframes load4 {
  0%,
  100% {
  box-shadow: 0em -3em 0em 0.2em #e40000, 2em -2em 0 0em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 -0.5em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 0em #e40000;
  }
  12.5% {
  box-shadow: 0em -3em 0em 0em #e40000, 2em -2em 0 0.2em #e40000, 3em 0em 0 0em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 -0.5em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  25% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 0em #e40000, 3em 0em 0 0.2em #e40000, 2em 2em 0 0em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 -0.5em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  37.5% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 0em #e40000, 2em 2em 0 0.2em #e40000, 0em 3em 0 0em #e40000, -2em 2em 0 -0.5em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  50% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 0em #e40000, 0em 3em 0 0.2em #e40000, -2em 2em 0 0em #e40000, -3em 0em 0 -0.5em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  62.5% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 0em #e40000, -2em 2em 0 0.2em #e40000, -3em 0em 0 0em #e40000, -2em -2em 0 -0.5em #e40000;
  }
  75% {
  box-shadow: 0em -3em 0em -0.5em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 0em #e40000, -3em 0em 0 0.2em #e40000, -2em -2em 0 0em #e40000;
  }
  87.5% {
  box-shadow: 0em -3em 0em 0em #e40000, 2em -2em 0 -0.5em #e40000, 3em 0em 0 -0.5em #e40000, 2em 2em 0 -0.5em #e40000, 0em 3em 0 -0.5em #e40000, -2em 2em 0 0em #e40000, -3em 0em 0 0em #e40000, -2em -2em 0 0.2em #e40000;
  }
  }

  .loader:before,
  .loader:after,
  .loader {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
  margin: 8em auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
  }
  .loader:before {
  left: -3.5em;
  }
  .loader:after {
  left: 3.5em;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
  }
  .loader:before,
  .loader:after {
  content: '';
  position: absolute;
  top: 0;
  }
  @-webkit-keyframes load7 {
  0%,
  80%,
  100% {
  box-shadow: 0 2.5em 0 -1.3em #e40000;
  }
  40% {
  box-shadow: 0 2.5em 0 0 #FFF;
  }
  }
  @keyframes load7 {
  0%,
  80%,
  100% {
  box-shadow: 0 2.5em 0 -1.3em #e40000;
  }
  40% {
  box-shadow: 0 2.5em 0 0 #FFF;
  }
  }
  
.NoInput{
  width: 13px!important;
}



.containerExport{
  float: right;
  cursor: pointer;
}


.botonExpense{
  height: 25px !important;
}

.containerExportC {
 float: right;
  cursor: pointer;

}

.drangAndDropExpense{
  border: dashed red;
  width: 100%;
  margin: 0 auto;
}

.drangAndDropExpense{
  cursor:pointer;
}

.ColorBreadCrumb{
  color: #059 !important;
}

.subtablaexpensesw{
  width: 95%;
  margin: 0 auto;
}
.subtablaexpensesw  .sticky-table-table{
  width: 100% !important;
}