.gridann{
    margin: 0 auto;
    display: grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 15% 15% 15% 15%;
    grid-template-areas:
    "a b";
    grid-gap:10px;
    z-index: 10;
    position: relative;

  }
.ann2{
    grid-area: a;
}
.ann2 input{
  height: 47px;
  font-size: 19px;
  border: 2px solid rgba(0, 0, 0, 0.249);
}
.ann2 label{
  font-size: 22px;
  color: black;
  background-color: #fafafa;
  margin-top: -2px;
}

.ann3{
    grid-area: b;
    padding-bottom: 8px;
}
.ann3 button{
    height: 45px;
    width: 80%;
    color: white;
}
.tableanual{
  margin-top: -70px;
}
.semaforo{
  width: 25px;
  height: 25px;
}
.TableSummaryAnual .sticky-table-table{
  width: 100%;
}
.TableSummaryAnual {
  margin: 0 auto;
  width: 95% !important;
}

.TableSummaryAnual td:nth-child(1){
  text-align: center;
}
.TableSummaryAnual td:nth-child(2){
  text-align: center;
}
.TableSummaryAnual th:nth-child(2){
  max-width: 100px !important;
}
.TableSummaryAnual th:nth-child(2) select{
  width: 100% !important;
  text-align: left;
}

.TableSummaryAnual td:nth-child(3){
  max-width: 200px;
  white-space: normal !important;
  word-wrap: break; 
  text-align: left;

}
.TableSummaryAnual td:nth-child(4){
  text-align: center;
}

.TableSummaryAnual td:nth-child(5),
.TableSummaryAnual td:nth-child(6),
.TableSummaryAnual td:nth-child(7),
.TableSummaryAnual td:nth-child(8),
.TableSummaryAnual td:nth-child(9),
.TableSummaryAnual td:nth-child(10),
.TableSummaryAnual td:nth-child(11),
.TableSummaryAnual td:nth-child(12),
.TableSummaryAnual td:nth-child(13)
.TableSummaryAnual td:nth-child(14),
.TableSummaryAnual td:nth-child(15),
.TableSummaryAnual td:nth-child(16),
.TableSummaryAnual td:nth-child(17){
  text-align: right;
}
.TableSummaryAnual td:nth-child(18){
  text-align: right;
}

.deletepos{
  background-color: red !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.semaforo-celda{
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}