.footer-table-container {
  float: left;
  padding-top: 10px;
  font-size: 25px;
}
.exportartablecontainer {
  z-index: 150;
  position: relative;
  width: 25px;
}
.exportartablecontainerpdf {
  z-index: 150;
  position: relative;
  width: 30px;
}
.icono-gs {
  z-index: 150;
  position: relative;
}
.icono-zip{
  width: 50px;
  z-index: 150;
  margin-left: 3px;
}
.icono-zip:hover{
  cursor: pointer;
}

.footer-table-container {
  font-size: 25px !important;
}
.expen-6-pad {
  margin-bottom: 42px;
}

.right_data {
  text-align: right !important;
  white-space: normal;
}

.center_data {
  text-align: center !important;
  white-space: normal;
}

.lef_data {
  text-align: left !important;
  white-space: normal;
}

.justify_data {
  text-align: justify !important;
  white-space: normal;
}

.modalmini{
  width: 60% !important;
}

.custom-modal {
  max-width: 600px;
  margin: 0 auto;
  padding: 0px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100 !important;
}

.custom-modal h3 {
  text-align: center;
}
.custom-modal .modal-content {
  margin-top: 10px;
}

.custom-modal .modal-footer {
  text-align: right;
  margin-top: 20px;
}

.custom-modal .modal-footer button {
  padding: 8px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.custom-modal .modal-footer button:hover {
  background-color: #0056b3;
}



.resizer {
  display: inline-block;
  background: whitesmoke;
  width: 3px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  user-select: none;

}
.isResizing {
  background-color: #ff0000; /* Cambia el color de fondo durante el redimensionamiento si lo deseas */
  user-select: none;

}


.icon-export {
  z-index: 150;
  width: 50px;
}

.icon-export-pdf {
  z-index: 150;
  width: 40px;
  margin-left: 4px;
}
.icon-export-gs {
  z-index: 150;
  width: 30px;
  margin-left: 4px;
}

.icon-acciones-dt{
  width: 50px;
  cursor: pointer !important;
}

.contenier-menu-icon div{
  background-color: transparent !important;
  border: 0px !important;

} 

.fijoHeader{
  /* position: fixed;
  top: 70px !important;
  z-index: 100; */
}
.contenier-menu-icon{
  cursor: pointer !important;
}

.grid-container-search_filters {
  padding-left: 12px;
  display: grid;
  width: 95%;
  grid-template-columns: repeat(auto-fill, minmax(10%, 1fr)); /* Distribuye equitativamente en columnas */
  gap: 10px; /* Espacio entre elementos */
  position: absolute;
  z-index: 20;
}






.tableFixHead {
  overflow: auto;
  width: 100%;
  height: 60vh;
}

.tableFixHead2-0 {
  overflow: auto;
  width: 100%;
  height: 80vh;
}
.tableFixHeadDashboard {
  overflow: auto;
  width: 100%;
  height: 650px !important;
}
.tableFixHead thead th {
  position: sticky !important;
  top: 0;
  z-index: 1;
}
.tableFixHeadDashboard thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableFixHead2 {
  overflow: auto;
  width: 100%;
  height: 450px !important;
}

.tableFixHead2 thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}




