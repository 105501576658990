/*containerFactureResponse-1 fact-1... fact-5 */
.containerDebts {
  width: 90%;
  margin: 0 auto;
}
.bp-1 {
  grid-area: cp1;
}
.bp-2 {
  grid-area: cp2;
}
.bp-3 {
  grid-area: cp3;
}
.bp-4 {
  grid-area: cp4;
}
.bp-5 {
  grid-area: cp5;
}
.bp-6 {
  grid-area: cp6;
}
.bp-7 {
  text-align: center;
  grid-area: cp7;
}

.bp-8 {
  grid-area: cp8;
}
.bp-9 {
  grid-area: cp9;
}
.bp-10 {
  grid-area: cp10;
}
.bp-11 {
  grid-area: cp11;
}
.bp-12 {
  grid-area: cp12;
}
.bp-13 {
  grid-area: cp13;
}
.bp-14 {
  grid-area: cp14;
}
.bp-15 {
  grid-area: cp15;
}
.bp-16 {
  grid-area: cp16;
}
.bp-17 {
  grid-area: cp17;
}
.bp-18 {
  grid-area: cp18;
}
.bp-19 {
  grid-area: cp19;
}
.bp-20 {
  grid-area: cp20;
}

.bp-21 {
  grid-area: cp21;
}

.bp-22 {
  grid-area: cp22;
}

.bp-23 {
  grid-area: cp23;
}

.bp-24 {
  grid-area: cp24;
}
.bp-25 {
  grid-area: cp25;
  text-align: center;
}

.containerDebts {
  display: grid;
  grid-template-areas:
    "cp1 cp1 cp2 cp2 cp5 cp5"
    "cp4 cp4 cp4 cp3 cp3 cp3"
    "cp12 cp12 cp7 cp7 cp6 cp6"
    "cp8 cp8 cp8 cp8 cp8 cp8"
    "cp10 cp10 cp10 cp11 cp11 cp11";
  gap: 10px;
}

p.validate_data {
  color: red !important;
  text-align: "justify";
  margin: 0 auto;
  width: 90%;
}

.containerProducts02 {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}


.modalProduct{
  max-width: 80% !important;
}

.AddData{
  width: 150px;
  cursor: pointer;
}

.TableProduct thead th:nth-child(1){
  white-space: normal !important;
  width: 80 !important;
  max-width: 80 !important;
}
.TableProduct td th:nth-child(1){
  white-space: normal !important;
  width: 80 !important;
  max-width: 80 !important;
}

.AddDatapointer{
  cursor: pointer;
}


.selectProduct{
  width: 150px;
  position: absolute;
  margin-left: 2.8%;
  margin-top: 20px;
  font-size: 18px;

}
.selectProduct select{
  height: 40px;
}



.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
padding: 1px !important;
}


.TableDebts thead tr th:nth-child(1){
  white-space: normal !important;
  width: 50px !important;
  max-width: 50px !important;
}
.TableDebts td:nth-child(1){
  white-space: normal !important;
  width: 50px !important;
  max-width: 50px !important;
  float: left;
}

.TableDebts thead tr th:nth-child(2),
.TableDebts thead tr th:nth-child(5),
.TableDebts thead tr th:nth-child(7),
.TableDebts thead tr th:nth-child(8),
.TableDebts thead tr th:nth-child(9),
.TableDebts thead tr th:nth-child(10),
.TableDebts thead tr th:nth-child(11),
.TableDebts thead tr th:nth-child(12){
  width: auto !important;
  max-width: 250px !important;
  text-align: justify;
}

.TableDebts thead tr th:nth-child(7),
.TableDebts thead tr th:nth-child(9),
.TableDebts thead tr th:nth-child(14){
  white-space: normal !important;
  text-align: justify;

}

.TableDebts td:nth-child(2),
.TableDebts td:nth-child(5),
.TableDebts td:nth-child(7),
.TableDebts td:nth-child(8),
.TableDebts td:nth-child(9),
.TableDebts td:nth-child(10),
.TableDebts td:nth-child(11),
.TableDebts td:nth-child(12){
  white-space: normal !important;
  width: auto !important;
  max-width: 250px !important;
  text-align: justify;

}


.TableDebts thead tr th:nth-child(14),
.TableDebts td:nth-child(17){
  text-align: center;
}


.TableDebts td:nth-child(13),
.TableDebts td:nth-child(14),
.TableDebts td:nth-child(15),
.TableDebts td:nth-child(16){
  text-align: right;
}


.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input{
  padding: 1px !important;
}